<template>
    <Toast />
    <div>
        <div>
            <component :is="layout" />
        </div>
    </div>
</template>

<script>
import MainLayout from './views/layouts/main/MainLayout.vue';
import Toast from 'primevue/toast';

export default {
  name: 'App',

  components: {
      MainLayout, Toast
  },

  data: () => ({
    layout: 'MainLayout',
    defaultLayout: 'MainLayout',
  }),

  watch: {
      $route(to) {
            if(to.meta.layout !== undefined && this.layout !== to.meta.layout) {
                this.layout = to.meta.layout;
            } else {
                this.layout = this.defaultLayout;
            }
        }
  }
};
</script>

<style>
    html { overflow-y: auto !important; }
</style>
