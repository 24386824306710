import { ref, reactive, watch, computed, onMounted } from 'vue';
import { defineStore } from 'pinia';
import { replaceColumns } from '@/helpers/strings';
import { CookieStorage, LocalStorage, MemoryStorage, SessionStorage, Storage } from 'frontend-storage';

// Define your store using the Composition API
export const useGeneralStore = defineStore('generalStore', () => {

    // Vars
    const definedProducts = ref([]);
    // Split columns for dynamic assign
    const definedProductsColumns = computed(() => {
        let inputArray = definedProducts.value;
        let keys = new Set();
        inputArray.forEach(obj => {
            Object.keys(obj).forEach(key => {
                keys.add(key);
            });
        });

        let result = [];
        keys.forEach(key => {
            result.push({ key: key });
        });

        return result;
    });

    // Const change page request
    const pageRequest = ref(0);
    const changePage = function (page) {
        if (page > 0 && page < 5) {
            pageRequest.value = page - 1;
        }
    };

    // Sendinblue API request
    const sendinblueAPIToken = ref('');

    // Load config
    const loadConfig = function() {
        if (SessionStorage.isAvailable) {
            const stor = new LocalStorage('mailconfig');
            let apiKey = stor.get('apiKey');
            sendinblueAPIToken.value = apiKey;
            getSenders();
        } else {
            console.log('Hay errores. No se puede cargar la clave del API.');
        }
    };

    // HTML
    const html = ref('');

    // Send data
    const definedSubject = ref('');
    const definedToaddress = ref('');
    const definedToname = ref('');
    const definedSender = ref({});
    const definedSenderName = ref('');
    const definedReplyTo = ref('');
    const definedReplyToName = ref('');

    const senders = ref([]);
    const getSenders = async function () {
        fetch('https://api.brevo.com/v3/senders', {
            method: 'GET',
            headers: {
                accept: 'application/json',
                'api-key': sendinblueAPIToken.value
            }
        })
            .then(response => response.json())
            .then(response => { senders.value = response.senders; })
            .catch(err => console.error(err));
    };

    const sendMailsOk = ref([]);
    const sendMailsFailed = ref([]);
    const sendMailsReset = function() {
        sendMailsOk.value = [];
        sendMailsFailed.value = [];
    };
    const sendMail = async function(obj) {
        let htmlcorr = html.value + '<style>p { margin: 0; padding: 0;}</style>';
        fetch('https://api.brevo.com/v3/smtp/email',
        {
            method: 'POST',
            headers: {
                accept: 'application/json',
                'content-type': 'application/json',
                'api-key': sendinblueAPIToken.value
            },
            body: JSON.stringify({
                sender: { 
                    name: definedSenderName.value.trim() == '' ? definedSender.value.name : definedSenderName.value, 
                    email: definedSender.value.email
                },
                to: [{ 
                    email: replaceColumns(definedToaddress.value, definedProducts.value, 
                        definedProductsColumns.value, definedProducts.value.indexOf(obj)),
                    name: replaceColumns(definedToname.value, definedProducts.value, 
                        definedProductsColumns.value, definedProducts.value.indexOf(obj)),
                }],
                htmlContent: replaceColumns(htmlcorr, definedProducts.value, 
                    definedProductsColumns.value, definedProducts.value.indexOf(obj)),
                subject: replaceColumns(definedSubject.value, definedProducts.value, 
                    definedProductsColumns.value, definedProducts.value.indexOf(obj)),
                replyTo: definedReplyTo.value.trim() ? {
                    email: replaceColumns(definedReplyTo.value, definedProducts.value, 
                        definedProductsColumns.value, definedProducts.value.indexOf(obj)),
                    name: replaceColumns(definedReplyToName.value, definedProducts.value, 
                        definedProductsColumns.value, definedProducts.value.indexOf(obj)),
                } : undefined
            })
        })
        .then(response => { response.json(); sendMailsOk.value.push(response);})
        .catch(err => { sendMailsFailed.value.push(err); });
    };

    loadConfig();

return {
    definedProducts, pageRequest, changePage, definedProductsColumns, sendMail,
    html, sendinblueAPIToken, getSenders, senders,
    definedSubject, definedToaddress, definedToname, definedSender, definedSenderName,
    definedReplyTo, definedReplyToName,
    sendMailsOk, sendMailsFailed, sendMailsReset, loadConfig
};
});