import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { loadFonts } from './plugins/webfontloader';

// PrimeVUE
import PrimeVue from 'primevue/config';
import esLang from '@/helpers/lang/primevue/es.js';
import Ripple from 'primevue/ripple';
import StyleClass from 'primevue/styleclass';
import DialogService from 'primevue/dialogservice';
import Tooltip from 'primevue/tooltip';
import { createPinia } from 'pinia';
import '@/themes/default.css';                                // Theme
import 'primevue/resources/primevue.min.css';                 // Core css
import 'primeicons/primeicons.css';                           // Icons
import 'primeflex/primeflex.css';                             // Primeflex

// Component bus
import mitt from 'mitt';

// Primevue services
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

loadFonts();

// Create app
const app = createApp(App);

// Inject emitter
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

// Mount app
app.use(createPinia())
.directive('ripple', Ripple)
.directive('styleclass', StyleClass)
.directive('tooltip', Tooltip)
.use(router)
.use(PrimeVue, 
    { 
        ripple: true,
        locale: esLang
    }
)
.use(DialogService)
.use(ConfirmationService)
.use(ToastService)
.mount('#app');

