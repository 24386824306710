<template>
    <div class="loginDialogContainer">
        
    </div>
    <HeaderMainLayout />
    
    <div class="pagecontainercomplete">
        <router-view />
    </div>
</template>

<script>
import { defineComponent } from 'vue';

// Header and footer
import HeaderMainLayout from './_components/HeaderMainLayout.vue';

export default defineComponent({
    name: 'MainLayout',

    components: {
        HeaderMainLayout
    },

    setup() {
        return {

        };
    },
});
</script>

<style>
@media only screen and (min-width:80rem) { 
	.pagecontainedwidth {
        width: 80rem;
    }
}

@media only screen and (max-width:80rem) {
    .pagecontainedwidth {
        width: 100%;
    }
}
</style>