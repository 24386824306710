import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  // Main web layout
  {
    path: '/',
    name: 'Inicio',
    component: () => import(/* webpackChunkName: 'testc' */ '@/components/HelloWorld.vue'),
    children: [
      { path: '/html', component: () => import('@/components/MailSender/HTMLComponent.vue') },
      { path: '/send', component: () => import('@/components/MailSender/EnviarComponent.vue') },
      { path: '/process', component: () => import('@/components/MailSender/ProcesarComponent.vue') },
      { path: '/tabla', component: () => import('@/components/MailSender/TablaComponent.vue') }
    ],
  },

  // This is for handle 404
  {
    path: '/:catchAll(.*)*',
    component: () => import('@/views/errors/HTTP404.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
