<template>
    <OptionsDialog v-model:modelValue="optionsDialogOpen" />
    <div class="flex align-items-center flex-column  headergrid">
        <div
            class="pagecontainedwidth headercontainer px-2 flex align-items-center justify-content-between relative lg:static">
            <Image :src="require('@/assets/brand/logo/MainLogoNegative.png')" height="37" alt="Logo de Suricatta MailSender"
                class="mr-0 mt-1 lg:mr-6" />
            <a v-ripple class="cursor-pointer block lg:hidden text-indigo-50 p-ripple"
                v-styleclass="{ selector: '@next', enterClass: 'hidden', leaveToClass: 'hidden', hideOnOutsideClick: true }">
                <i class="pi pi-bars text-4xl"></i>
            </a>
            <div
                class="align-items-center h-full flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none">
                <!-- Left side -->
                <div class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
                    <li v-if="false">
                        <a v-ripple
                            class="text-white bg-secondary-hover hover:text-black flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple">
                            <i class="pi pi-chart-pie mr-2"></i>
                            <span>Últimos chollos</span>
                        </a>
                    </li>
                    <li v-if="false">
                        <a v-ripple
                            class="text-white bg-secondary-hover flex px-6 p-3 lg:px-3 lg:py-2 align-items-center font-medium border-round cursor-pointer transition-colors transition-duration-150 p-ripple"
                            v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true }">
                            <i class="pi pi-sitemap mr-2"></i>
                            <span>Categorías</span>
                            <i class="pi pi-angle-down ml-auto lg:ml-3"></i>
                        </a>
                    </li>
                </div>

                <div
                    class="list-none h-full p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
                    <!-- Right side: Authenticated -->
                    <div
                        class="list-none h-full p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 border-indigo-400 lg:border-top-none">
                        <Button @click="user_menu.toggle($event)" label="Menu" />
                        <!-- User menu -->
                        <Menu ref="user_menu" id="overlay_user_menu" :popup="true" :model="menuItems"
                            class="shadow-6 bg-white">
                            <template #item>
                                <button
                                    class="w-full p-link flex align-items-center p-2 pl-4 text-black hover:bg-yellow-500 border-noround"
                                    @click="optionsDialogOpen = true">
                                    <i class="pi pi-check-circle" />
                                    <span class="ml-2">Opciones</span>
                                </button>
                            </template>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, reactive, watch } from 'vue';

// Components
import Image from 'primevue/image';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Avatar from 'primevue/avatar';
import Divider from 'primevue/divider';

import OptionsDialog from '@/components/Options/OptionsDialog';

export default {
    components: {
        Image, Button, Menu, Avatar, Divider, OptionsDialog
    },
    emits: [],
    setup() {
        // SECTION: User menu
        const user_menu = ref(null);

        const optionsDialogOpen = ref(false);

        // Dummy menu so the Menu Component shows the html in the 'Item' template
        const menuItems = [{ label: 'Dummy' }];

        return {
            user_menu, menuItems, optionsDialogOpen
        };
    }
};
</script>

<style>
.headergrid {
    background: #000411;
}

.headercontainer {
    height: 60px;
}

@media only screen and (min-width:1730px) {
    #overlay_user_menu {
        width: 14.5rem;
        margin-left: -180px;
        margin-top: 7px;
    }
}

@media only screen and (min-width:1344px) and (max-width: 1729px) {
    #overlay_user_menu {
        width: 14.5rem;
        margin-top: 7px;
    }
}

@media only screen and (min-width:992px) and (max-width: 1344px) {
    #overlay_user_menu {
        width: 14.5rem;
        margin-top: 7px;
    }
}
</style>

<style scoped>
.button-square {
    width: 40px;
    height: 40px;
}

.avatar-header {
    height: 40px;
    width: 40px;
}

.smallmenuavatar {
    font-size: 12px;
}

.colavatar .p-avatar-text {
    font-size: 0.8em;
}
</style>