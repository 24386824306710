// Get browser language with contry code
export function getRepresentativeLetters(text, rep = 2) {
    if(text == undefined) return '??';

    // Eliminar los caracteres que no son letras y convertir a mayúsculas
    text = text.replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ ]/g, '').toUpperCase();
    // Normalizar el string a Unicode y eliminar los caracteres que no sean ASCII
    text = text.normalize('NFD').replace(/[\u0080-\uFFFF]/g, '');
    // Dividir el nombre por los espacios
    let words = text.split(' ');
    // Inicializar un array vacío para guardar las letras representativas
    let letters = [];
    // Recorrer las palabras del nombre
    for (let word of words) {
        // Añadir la primera letra de cada palabra al array
        letters.push(word[0]);
        // Si el array tiene el mismo tamaño que rep, terminar el bucle
        if (letters.length === rep) {
            break;
        }
    }
    // Si el array tiene menos elementos que rep, añadir las letras restantes de la última palabra o ? si no hay más letras
    if (letters.length < rep) {
        let lastWord = words[words.length - 1];
        for (let i = 1; i < rep; i++) {
            // Si la última palabra tiene más letras, añadirlas al array
            if (lastWord[i]) {
                letters.push(lastWord[i]);
            } else {
                // Si no, añadir ?
                letters.push('?');
            }
        }
    }
    // Unir el array en un string y devolverlo
    return letters.join('');
}

// Define mapping for accented characters
const ACCENT_MAP = {
    // Spanish
    'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'ü': 'u', 'ñ': 'n',
    'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', 'Ü': 'U', 'Ñ': 'N',

    // Portuguese
    'ã': 'a', 'õ': 'o', 'ç': 'c',
    'Ã': 'A', 'Õ': 'O', 'Ç': 'C',

    // French
    'à': 'a', 'è': 'e', 'ì': 'i', 'ò': 'o', 'ù': 'u', 'û': 'u', 'ê': 'e', 'î': 'i', 'ô': 'o', 'â': 'a', 'ë': 'e', 'ï': 'i', 'ÿ': 'y', 'œ': 'oe', 'æ': 'ae',
    'À': 'A', 'È': 'E', 'Ì': 'I', 'Ò': 'O', 'Ù': 'U', 'Û': 'U', 'Ê': 'E', 'Î': 'I', 'Ô': 'O', 'Â': 'A', 'Ë': 'E', 'Ï': 'I', 'Ÿ': 'Y', 'Œ': 'OE', 'Æ': 'AE',

    // German
    'ä': 'a', 'ö': 'o', 'ß': 'ss',
    'Ä': 'A', 'Ö': 'O',

    // Space
    ' ': '_'
};

// Function to replace accented characters
export function replaceAccents(str) {
    return str.split('').map(char => ACCENT_MAP[char] || char).join('').replace(/[^a-zA-Z0-9_]/g, '');
}

export function replaceColumns(html, definedProducts, definedProductsColumns, numItem) {
    definedProductsColumns.forEach(element => {
        let replacepattern = `%%col.${element.key}%%`;
        let replaceBy = '';

        if(definedProducts.length > numItem && numItem >= 0) {
            replaceBy = definedProducts[numItem][element.key];
        }

        html = html.split(replacepattern).join(replaceBy);
    });

    return html;
}