<template>
    <Dialog v-model:visible="visible" modal header="Configuración" :style="{ width: '50vw' }">
        <h3 class="mt-2 mb-0">Clave de api</h3>
        <p>Introduzca una clave de API de Brevo.</p>
        <InputText type="text" v-model="apiKey" class="w-full" />
        <Button label="Guardar" class="text-white mt-4" @click="save" />
    </Dialog>
</template>

<script>
import { ref, reactive, watch, toRefs, onMounted } from 'vue';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { CookieStorage, LocalStorage, MemoryStorage, SessionStorage, Storage } from 'frontend-storage';
import { useToast } from 'primevue/usetoast';

import { useGeneralStore } from '@/store/GeneralStore';

export default {
    components: {
        Dialog, InputText, Button
    },
    props: {
        modelValue: {
            type: Boolean,
            default: false, 
        },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const { modelValue } = toRefs(props);
        const visible = ref(false);
        const toast = useToast();
        const generalStore = useGeneralStore();

        // Este watcher actualiza el valor de visible y emite el evento cuando modelValue cambia
        watch(modelValue, newVal => {
            visible.value = newVal;
        });

        watch(visible, newVal => {
            context.emit('update:modelValue', newVal);
        });

        // API Key
        const apiKey = ref('');

        const save = function() {
            if (SessionStorage.isAvailable) {
                const stor = new LocalStorage('mailconfig');
                stor.set('apiKey', apiKey.value.trim());
                toast.add({ severity: 'success', summary: 'Guardado correctamente', 
                    detail: 'Se ha guardado la configuración', life: 5000 });
                visible.value = false;

                generalStore.loadConfig();

            } else {
                toast.add({ severity: 'error', summary: 'Hubo un error al guardar', 
                    detail: 'No se pudo guardar la configuración. Error.', life: 5000 });
            }
        };

        const load = function() {
            if (SessionStorage.isAvailable) {
                const stor = new LocalStorage('mailconfig');
                apiKey.value = stor.get('apiKey');
            } else {
                toast.add({ severity: 'error', summary: 'Hubo un error al cargar', 
                    detail: 'Hay errores. No se puede cargar la configuración.', life: 5000 });
            }
        };

        onMounted(() => {
            load();
        });

        return {
            visible, apiKey, save
        };
    }
};
</script>

<style>

</style>